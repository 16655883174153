import {
    DF_BIRD_FILENAME_V1_0_0,
    DF_BIRD_FILENAME_V1_0_1,
    DF_BIRD_FILENAME_V1_0_2,
    DF_BIRD_FILENAME_V1_0_3,
    DF_BIRD_URL_V1_0_0,
    DF_BIRD_URL_V1_0_1,
    DF_BIRD_URL_V1_0_2,
    DF_BIRD_URL_V1_0_3,
} from "../../consts/Installers";

/**
 * リリースノート（DF BIRD）
 * @returns JSX.Element
 */
export const ReleaseNotesDfBird = () => {
    return (
        <div>
            {/* v1.0.3 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF BIRD v1.0.3</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-11-29</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF BIRD v1.0.3をリリースしました。</p>
                    <br />
                    <p>機能改善</p>
                    <p>・オルソ画像作成精度が向上しました。</p>
                    <p>・処理完了時の画像インポートを自動化しました。</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">ダウンロード</p>
                <a className="link-text-blue" href={DF_BIRD_URL_V1_0_3}>
                    {DF_BIRD_FILENAME_V1_0_3}
                </a>
            </div>

            {/* v1.0.2 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF BIRD v1.0.2</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-11-08</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF BIRD v1.0.2をリリースしました。</p>
                    <br />
                    <p>機能改善</p>
                    <p>・SfM処理を高速化しました。</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">ダウンロード</p>
                <a className="link-text-blue" href={DF_BIRD_URL_V1_0_2}>
                    {DF_BIRD_FILENAME_V1_0_2}
                </a>
            </div>

            {/* v1.0.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF BIRD v1.0.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-10-25</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF BIRD v1.0.1をリリースしました。</p>
                    <br />
                    <p>バグ修正</p>
                    <p>・一部のPCで起動しない問題を修正しました。</p>
                    <p>・一部のオルソ画像が適切に描画されない問題を修正しました。</p>
                    <p>・ポリゴン描画の不具合を修正しました。</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">ダウンロード</p>
                <a className="link-text-blue" href={DF_BIRD_URL_V1_0_1}>
                    {DF_BIRD_FILENAME_V1_0_1}
                </a>
            </div>

            {/* v1.0.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF BIRD v1.0.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-09-18</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF BIRD v1.0.0をリリースしました。</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">ダウンロード</p>
                <a className="link-text-blue" href={DF_BIRD_URL_V1_0_0}>
                    {DF_BIRD_FILENAME_V1_0_0}
                </a>
            </div>
        </div>
    );
};

export default ReleaseNotesDfBird;

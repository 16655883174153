import { HashLink } from "react-router-hash-link";
import DTMLink from "./DTMLink";
import { DTM_LINK_ROUTE } from "../../consts/routes";
import { Link } from "react-router-dom";

/**
 * Q&A画面 - 日本語 -
 * @returns
 */
const QAContentJA = () => {
    const scrollWidthOffset = (el: any) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -40;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    };

    return (
        <div className="mx-auto max-w-3xl">
            {/* ナビゲーションメニュー */}
            <div className="p-4">
                <nav>
                    <ul>
                        <li className="mb-4">
                            <HashLink
                                className="text-xl font-bold text-blue-500 hover:underline"
                                to={"#section1"}
                                scroll={(el) => scrollWidthOffset(el)}
                                smooth
                            >
                                ドローン飛行について
                            </HashLink>
                            <ul className="ml-4 mt-2 text-lg">
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section1-1"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q1-1. 推奨の機体はありますか？
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section1-2"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q1-2. 推奨の飛行設定はありますか？
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink className="text-blue-500 hover:underline" to={"#section1-3"} smooth>
                                        Q1-3. 地形に合わせて自動操縦するにはどうしたらいいですか？
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink className="text-blue-500 hover:underline" to={"#section1-4"} smooth>
                                        Q1-4. 推奨のSfMソフトについて教えて下さい。
                                    </HashLink>
                                </li>
                            </ul>
                        </li>
                        <li className="mb-4">
                            <HashLink
                                className="text-xl font-bold text-blue-500 hover:underline"
                                to={"#section2"}
                                scroll={(el) => scrollWidthOffset(el)}
                                smooth
                            >
                                DF Scannerについて
                            </HashLink>
                            <ul className="ml-4 mt-2 text-lg">
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section2-1"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q2-1. 樹種識別の精度が出ない場合はどうしたらよいですか？
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section2-2"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q2-2. 解析精度について知りたいです。
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section2-3"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q2-3. 樹頂点検出の詳細パラメータの設定について知りたいです。
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section2-4"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q2-4. 日本以外の地面高さ情報（DTM）はどのように取得したらよいですか？
                                    </HashLink>
                                </li>
                            </ul>
                        </li>
                        <li className="mb-4">
                            <HashLink
                                className="text-xl font-bold text-blue-500 hover:underline"
                                to={"#section3"}
                                scroll={(el) => scrollWidthOffset(el)}
                                smooth
                            >
                                DF LATについて
                            </HashLink>
                            <ul className="ml-4 mt-2 text-lg">
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section3-1"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q3-1. ソフトウェアの起動ができません。
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section3-2"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q3-2. Zenmuse L2の解析がうまくいきません。
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        className="text-blue-500 hover:underline"
                                        to={"#section3-3"}
                                        scroll={(el) => scrollWidthOffset(el)}
                                        smooth
                                    >
                                        Q3-3. 地面部分のみのデータの作成ではなく、クラス分類を行いたい。
                                    </HashLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>

            <hr className="my-1 border-2"></hr>

            {/* 回答 */}
            <div className="">
                {/* ドローン飛行について */}
                <div className="mx-2 mt-8">
                    <h2 id="section1" className="my-2 text-center text-xl font-bold text-lime-800">
                        ドローン飛行について
                    </h2>

                    {/* 1-1 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section1-1" className="my-2 text-lg font-bold">
                            Q1-1. 推奨の機体はありますか？
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">
                                特に日本では傾斜が急の為、地形に追従して高度を変更しながら計測できる機体を推奨しています。
                                <br />
                                下記DJI製ドローンを推奨しています。
                            </p>
                            <p className="mt-2 font-semibold">デジカメでの撮影の場合</p>
                            <ul className="ml-5 list-disc">
                                <li>Phantom 4 Pro RTK</li>
                                <li>Mavic 3 Enterprise RTK</li>
                            </ul>
                            <p className="mt-2 font-semibold">レーザードローンの場合</p>
                            <ul className="ml-5 list-disc">
                                <li>Matrice 300, 350 & Zenmuse L1, L2</li>
                            </ul>
                            <p className="mt-2">
                                それ以外のMavic 2
                                Proなどのドローン等でも、計測諸元を守り地面が写るように撮影を行うことで、少し精度が下がりますが解析が可能です。計測諸元は下記の質問を参照してください。
                            </p>
                        </div>
                    </div>

                    {/* 1-2 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section1-2" className="my-2 text-lg font-bold">
                            Q1-2. 推奨の飛行設定はありますか？
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">以下が推奨の条件になります。</p>
                            <table className="mx-5 mt-2 border">
                                <tr>
                                    <td className="col-span-2 px-2">推奨計測諸元</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">対地速度</td>
                                    <td className="border px-2">5m/s</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">地上解像度</td>
                                    <td className="border px-2">2~3cm</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">対地高度</td>
                                    <td className="border px-2">80~100m※</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">オーバーラップ</td>
                                    <td className="border px-2">85%以上</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">サイドラップ</td>
                                    <td className="border px-2">75%以上</td>
                                </tr>
                            </table>
                            <p className="mt-2">
                                ※対地高度は搭載しているカメラにより地上解像度に合わせて変更が必要となります。2000万画素を搭載しているカメラであれば約100mからの計測、1200万画素のカメラであれば約80mからの計測となります。
                            </p>
                            <p className="">DJIの一般的なphantom4、mavic2-3シリーズは2000万画素です。</p>
                            <table className="mx-5 mt-6 border">
                                <tr>
                                    <td className="col-span-2 px-2">推奨計測時期</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">シーズン</td>
                                    <td className="border px-2">緑葉のシーズン（５月～9月）</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">天候</td>
                                    <td className="border px-2">曇り</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">時間帯</td>
                                    <td className="border px-2">10~14時頃</td>
                                </tr>
                            </table>
                            <p className="mt-2">
                                曇天時、もしくは晴天時で影の少ない10～14時頃を目安とした時間帯での撮影を推奨しております。
                            </p>
                        </div>
                    </div>

                    {/* 1-3 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section1-3" className="my-2 text-lg font-bold">
                            Q1-3. 地形に合わせて自動操縦するにはどうしたらいいですか？
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">
                                日本では国土地理院が公開している10mDEMを活用することで、地形に合わせてフライトの設定を行うことが可能です。
                            </p>
                            <a
                                className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                href={"https://fgd.gsi.go.jp/download/menu.php"}
                                target="_blank"
                            >
                                参考) 基盤地図情報 ダウンロードサービス
                            </a>
                            <p className="mt-2">
                                Matrice350などRTK対応のドローンに関してはDJIのアプリで地形データに対応して飛行させることが可能です。下記の流れになります。
                            </p>
                            <ul className="ml-5 list-decimal">
                                <li>
                                    国土地理院DEMをtifに変換
                                    <br />
                                    こちらはDF
                                    Scannerのファイル＞インポート＞国土地理院DEMをインポートより変換することが可能です。
                                </li>
                                <li>
                                    ジオイド高の追加
                                    <br />
                                    以下サイトより対象エリアのジオイド高を調べることができます。QGIS等のGISソフトでラスター計算により、tifデータにジオイド高を追加します。
                                    <br />
                                    <a
                                        className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                        href={"https://vldb.gsi.go.jp/sokuchi/surveycalc/geoid/calcgh/calc_f.html"}
                                        target="_blank"
                                    >
                                        参考) ジオイド高計算
                                    </a>
                                </li>
                                <li>
                                    DJI Pilotアプリでの読み込み
                                    <br />
                                    フライト設定の中でDSMをインポートという箇所があるので、microSDで移したデータを読み込むことで、国土地理院DEMに合わせた飛行を設定することが可能です。
                                </li>
                            </ul>
                            <p className="mt-2">
                                RTK対応していない、Mavic 2
                                Proなどはサードパーティ製のアプリを使うことで地形に合わせて飛行させることができる場合があります。
                            </p>
                            <p className="mt-2">
                                以下はUGCSを使用した場合の自動飛行設定の紹介です。
                                <br />
                                <a
                                    className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                    href={"/pdf/UGCSを用いた自動飛行設定.pdf"}
                                    target="_blank"
                                >
                                    参考) UGCSを用いた自動飛行設定.pdf
                                </a>
                            </p>
                        </div>
                    </div>

                    {/* 1-4 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section1-4" className="my-2 text-lg font-bold">
                            Q1-4. 推奨のSfMソフトについて教えて下さい。
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">DF BIRD、Metashapeを推奨しています。</p>
                        </div>
                    </div>
                </div>

                {/* DF Scannerについて */}
                <div className="mx-2 mt-8">
                    <h2 id="section2" className="my-2 text-center text-xl font-bold text-lime-800">
                        DF Scannerについて
                    </h2>

                    {/* 2-1 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section2-1" className="my-2 text-lg font-bold">
                            Q2-1. 樹種識別の精度が出ない場合はどうしたらよいですか？
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">
                                推奨計測諸元や推奨時期以外で計測したデータの場合、精度がでないことがあります。その際はsite
                                tuningによる自身での教師データ作成を行い、識別精度を向上させて下さい。
                            </p>
                            <p className="mt-2">
                                また冬に撮影したデータなどは樹木の色が暗いあるいは赤い場合があり、識別精度が低い場合があります。
                            </p>
                            <p className="mt-2">
                                現在暗いデータの精度低下に対する対策を進めており、2024年度中に暗いデータに対してもある程度の精度で識別が可能になる予定です。
                            </p>
                        </div>
                    </div>

                    {/* 2-2 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section2-2" className="my-2 text-lg font-bold">
                            Q2-2. 解析精度について知りたいです。
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="font-semibold">樹種識別について</p>
                            <p className="">
                                スギ・ヒノキについては92%の精度、その他代表的な植生を代表するコナラ、ブナ、シイ、モミなどについては識別対象植生を設定することで60%程度の精度となっております。カラマツ・トドマツなど北海道の種についてはまだデータが少なく、精度数値はありません。
                            </p>
                            <p className="mt-2 font-semibold">樹高推定について</p>
                            <p className="">
                                レーザードローンで計測した場合、一般的に50cm~1m程度の誤差と言われています。SfMで作成したDSMと国土地理院の5m解像度のDEMデータからCHMを作成した場合、国土地理院DEMの誤差とドローンのXY方向の誤差等が影響し、平均的には1-3m程度の誤差が生じます。国土地理院DEMが10mの場合やCHM補正がうまくできない場合、誤差はさらに大きくなります。
                            </p>
                            <p className="mt-2 font-semibold">胸高直径推定について</p>
                            <p className="">
                                胸高直径は樹高、樹冠面積より推定しています。スギ・ヒノキの場合、平均で80%の推定精度になります。
                            </p>
                            <p className="mt-2 font-semibold">幹材積推定について</p>
                            <p className="">
                                幹材積は樹種、樹高、胸高直径より推定され、全体では本数が重要となります。そのため上記項目での計測・解析精度により変わります。
                            </p>
                        </div>
                    </div>

                    {/* 2-3 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section2-3" className="my-2 text-lg font-bold">
                            Q2-3. 樹頂点検出の詳細パラメータの設定について知りたいです。
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">
                                樹頂点検出の詳細設定では、2種類のパラメータを設定できます。※DF Scanner Pro版のみ対応
                            </p>
                            <p className="mt-2 font-semibold">平滑化カーネルサイズ</p>
                            <p>
                                画像中の細かな起伏を除去する度合いを表し、大きい値にするほど細かな起伏を無視するようになります。
                            </p>
                            <p className="mt-2 font-semibold">
                                Local Maximum Filterカーネルサイズ（LMFカーネルサイズ）
                            </p>
                            <p>
                                樹頂点を検出する頻度（検出する樹木の間隔）を表し、大きい値にするほど検出数が粗くなります。
                            </p>
                            <p className="mt-2">
                                多くの森林では、デフォルトの値（平滑化カーネルサイズ：0.8、LMFカーネルサイズ：1.2）が有効ですが、樹種や立木密度に応じてパラメータを調節することで検出精度が上がる場合があります。
                            </p>
                            <ul className="ml-5 list-disc">
                                <li>値を調整する場合は、0.1~0.2単位で変更してお試しいただくことを推奨致します。</li>
                                <li>以下は日本の主要な針葉樹林での設定例です。</li>
                            </ul>
                            <table className="mx-5 mt-2 border">
                                <tr>
                                    <th className="border px-2"></th>
                                    <th className="border px-2">平滑化カーネルサイズ</th>
                                    <th className="border px-2">LMFカーネルサイズ</th>
                                </tr>
                                <tr>
                                    <td className="border px-2">スギ林</td>
                                    <td className="border px-2">0.8</td>
                                    <td className="border px-2">1.2</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">ヒノキ林</td>
                                    <td className="border px-2">0.8</td>
                                    <td className="border px-2">1.2</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">カラマツ林</td>
                                    <td className="border px-2">2.0</td>
                                    <td className="border px-2">1.2</td>
                                </tr>
                                <tr>
                                    <td className="border px-2">トドマツ林</td>
                                    <td className="border px-2">2.0</td>
                                    <td className="border px-2">1.2</td>
                                </tr>
                            </table>
                            <p className="mt-2">
                                各パラメータについてより詳細に知りたい方は以下の資料をご確認ください。
                                <br />
                                <a
                                    className="text-base text-blue-500 hover:text-blue-700 hover:underline"
                                    href={"/pdf/樹頂点検出のパラメータについて.pdf"}
                                    target="_blank"
                                >
                                    参考) 樹頂点検出のパラメータについて.pdf
                                </a>
                            </p>
                        </div>
                    </div>

                    {/* 2-4 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section2-4" className="my-2 text-lg font-bold">
                            Q2-4. 日本以外の地面高さ情報（DTM）はどのように取得したらよいですか？
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">
                                CHM計算において、日本の場合はDTMデータとして国土地理院データを指定することができますが、海外利用の場合はご自身でDTMデータを用意いただく必要があります。
                            </p>
                            <p className="mt-2 font-semibold">レーザードローンを利用する場合</p>
                            <p className="">
                                測定した点群データをDF LATで解析することで簡単にDTMデータを作成することができます。
                            </p>
                            <p className="mt-2 font-semibold">カメラドローンを利用する場合</p>
                            <p className="">
                                原則、森林の地面情報の取得は難しいです。ただし、地面部分が多い画像であればSfM処理ソフトから地面データを作成できる場合もあります。
                            </p>

                            <p className="mt-2 font-semibold">ご自身での測定が困難な場合</p>
                            <p className="">
                                インターネット上に公開されているデータを使用して解析することが可能です。ただし、データを利用する際は以下の点にご注意ください。
                            </p>
                            <ul className="ml-5 list-disc">
                                <li>
                                    DF
                                    Scannerでは、GeoTIFF形式(.tif/.tiff)のデータのみ対応しています。データの形式が異なる場合は、事前に変換してからご利用ください。
                                </li>
                                <li>データをダウンロードする際は、必ず利用規約を確認し、自己責任でご利用ください。</li>
                                <li>
                                    インターネット上に公開されているデータは解像度が低い場合が多いです。DF
                                    Scannerでの解析は可能ですが、精度が十分に出ない場合があります。
                                </li>
                                <li>
                                    CHM計算後は必ず地面部分の高さを確認し、必要に応じて地面補正操作を行うようにしてください。
                                </li>
                            </ul>
                            <p className="mt-2">
                                <Link to={DTM_LINK_ROUTE} className="link-text-blue">
                                    参考) DTMダウンロードページ一覧
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>

                {/* DF LATについて */}
                <div className="mx-2 mt-8">
                    <h2 id="section3" className="my-2 text-center text-xl font-bold text-lime-800">
                        DF LATについて
                    </h2>

                    {/* 3-1 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section3-1" className="my-2 text-lg font-bold">
                            Q3-1. ソフトウェアの起動ができません。
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">
                                Ver
                                1.0.1にて本バグを修正しました。それでも起動できない場合は弊社までお問い合わせください。
                            </p>
                        </div>
                    </div>

                    {/* 3-2 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section3-2" className="my-2 text-lg font-bold">
                            Q3-2. Zenmuse L2の解析がうまくいきません。
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">
                                Zenmuse
                                L1に対し、L2で取得したデータは樹冠表面の反射点数が少なく、DSMで穴が開きやすいことが分かっています。そのためDSM作成時の解像度を10cmあるいは15cmに変更して作成を行ってください。
                            </p>
                        </div>
                    </div>

                    {/* 3-3 */}
                    <div className="my-5 rounded-xl border p-2">
                        <h3 id="section3-3" className="my-2 text-lg font-bold">
                            Q3-3. 地面部分のみのデータの作成ではなく、クラス分類を行いたい。
                        </h3>
                        <hr className="my-1"></hr>
                        <div className="m-2 text-gray-700">
                            <p className="">こちらについては現在開発を進めております。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QAContentJA;

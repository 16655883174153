import { useTranslation } from "react-i18next";
import { useUserContext } from "../../contexts/UserContext";

/** ダウンロード可能ファイルタイプ */
const DOWNLOADABLE_FILES = [".json", ".geojson", ".png", ".walker", ".tif", ".tiff"];
/** TIFF変換可能ファイルタイプ */
const TIFF_CONVERTABLE_FILES = [".tiff", ".tif"];

const ContextMenu = ({
    show,
    position,
    onClose,
    menuRef,
    downloadFile,
    selectedFile,
    setShowDeleteModal,
    openShowTiffConvertModal,
}: any) => {
    const { t } = useTranslation();
    const { user } = useUserContext();

    if (!show) {
        return null;
    }
    const menuStyle: React.CSSProperties = {
        position: "absolute",
        top: `${position.y}px`,
        left: `${position.x}px`,
        backgroundColor: "white",
        border: "1px solid #ddd",
        padding: "5px",
        zIndex: 1000,
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", // オプション: 少し影を追加
    };

    return (
        <>
            <style>{`.menu-item:hover {background-color: #f0f0f0;}`}</style>
            <ul style={menuStyle} ref={menuRef} onClick={onClose}>
                {/* 画像変換呼び出し */}
                {TIFF_CONVERTABLE_FILES.some((suffix) => selectedFile.key?.endsWith(suffix)) && (
                    <li
                        className="menu-item py-1.25 cursor-pointer px-2.5"
                        onClick={() => openShowTiffConvertModal(false)}
                    >
                        {t("CommonLabel.tiffConvert")}
                    </li>
                )}
                {/* 画像変換呼び出し（高画質版） */}
                {/* 【β版】DFメールアドレスユーザーのみ使えるようにする */}
                {TIFF_CONVERTABLE_FILES.some((suffix) => selectedFile.key?.endsWith(suffix)) &&
                    user &&
                    String(user.attributes.email).endsWith("@deepforest-tech.co.jp") && (
                        <li
                            className="menu-item py-1.25 cursor-pointer px-2.5 text-red-700"
                            onClick={() => openShowTiffConvertModal(true)}
                        >
                            {t("DataManagement.tiffConvertHq")}
                        </li>
                    )}
                {/* ダウンロード */}
                {DOWNLOADABLE_FILES.some((suffix) => selectedFile.key?.endsWith(suffix)) && (
                    <li className="menu-item py-1.25 cursor-pointer px-2.5" onClick={() => downloadFile(selectedFile)}>
                        {t("CommonLabel.download")}
                    </li>
                )}
                {/* 削除 */}
                <li className="menu-item py-1.25 cursor-pointer px-2.5" onClick={() => setShowDeleteModal(true)}>
                    {t("CommonLabel.delete")}
                </li>
            </ul>
        </>
    );
};
export default ContextMenu;

import { Route, Routes, BrowserRouter } from "react-router-dom";

import Top from "./pages/Top";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import SignupConfirm from "./pages/SignupConfirm";
import ResetPassword from "./pages/ResetPassword";
import RequiredSignin from "./pages/RequiredSignin";
import Mypage from "./pages/Mypage";
import AccountSetting from "./pages/AccountSetting";
import ProductSelect from "./pages/ProductSelect";
import ProductConfirm from "./pages/ProductConfirm";
import ProductComplete from "./pages/ProductComplete";
import UserLicense from "./pages/UserLicense";
import UserCreate from "./pages/UserCreate";
import UserUpdate from "./pages/UserUpdate";
import LiseLinking from "./pages/LicenseLinking";
import CommonError from "./pages/CommonError";
import "./App.css";
import {
    HOME_ROUTE,
    SIGNIN_ROUTE,
    SIGNUP_ROUTE,
    SIGNUP_CONFIRM_ROUTE,
    RESER_PASSWORD_ROUTE,
    REQUIRED_SIGNIN_ROUTE,
    PRODUCT_SELECT_ROUTE,
    PRODUCT_CONFIRM_ROUTE,
    PRODUCT_COMPLETE_ROUTE,
    USER_LICENSE_ROUTE,
    USER_CREATE_ROUTE,
    USER_UPDATE_ROUTE,
    LICENSE_LINKING_ROUTE,
    COMMON_ERROR_ROUTE,
    MYPAGE_ROUTE,
    ACCOUNT_SETTING_ROUTE,
    HELP_ROUTE,
    DETAIL_DF_SCANNER_ROUTE,
    DETAIL_DF_LAT_ROUTE,
    COMPLETE_PASSWORD_ROUTE,
    DETAIL_DF_VIEWER_ROUTE,
    COMMON_UNAUTHORIZED_ROUTE,
    PRODUCT_CONFIRM_TRIAL_ROUTE,
    LICENSE_DETAIL_ROUTE,
    PRODUCT_CONFIRM_UPDATE_ROUTE,
    PAYMENT_HISTORY_ROUTE,
    LICENSE_ISSUE_START_ROUTE,
    LICENSE_ISSUE_USER_REGISTER_ROUTE,
    LICENSE_ISSUE_CONFIRM_ROUTE,
    LICENSE_ISSUE_USER_SEARCH_ROUTE,
    LICENSE_ISSUE_PURCHASE_TYPE_ROUTE,
    LICENSE_ISSUE_COMPLETE_ROUTE,
    LICENSE_ISSUE_HISTORY_ROUTE,
    PRODUCT_CONFIRM_UPDATE_REQUEST_ROUTE,
    DOWNLOAD_ROUTE,
    RELEASE_NOTES_ROUTE,
    LICENSE_PURCHASE_FORM,
    USER_LICENSE_REFERENCE_ROUTE,
    DATA_MANAGEMENT_ROUTE,
    QA_ROUTE,
    WEB_GIS_ROUTE,
    DTM_LINK_ROUTE,
    PRIVACY_POLICY_ROUTE,
    PRIVACY_POLICY_EN_ROUTE,
    DETAIL_DF_WALKER_ROUTE,
    PAYMENT_COMPLETE_ROUTE,
    DETAIL_DF_BIRD_ROUTE,
    PRODUCT_CONFIRM_DFSCANNER_ROUTE,
    PRODUCT_CONFIRM_DFLAT_ROUTE,
} from "./consts/routes";
import RouteAuthGuard from "./components/RouteAuthGuard";
import Help from "./pages/Help";
import DetailDfscanner from "./pages/productDetails/DetailDfscanner";
import DetailDflat from "./pages/productDetails/DetailDflat";
import CompletePassword from "./pages/CompletePassword";
import ErrorBoundary from "./components/ErrorBoundary";
import DetailDfviewer from "./pages/productDetails/DetailViewer";
import CommonUnauthorized from "./pages/CommonUnAuthorized";
import CommonNotFound from "./pages/CommonNotFound";
import ProductConfirmTrial from "./pages/ProductConfirmForTrial";
import LicenseDetail from "./pages/LicenseDetail";
import ProductConfirmForUpdate from "./pages/ProductConfirmForUpdate";
import PaymentHistoryList from "./pages/PaymentHistory";
import LicenseIssueStart from "./pages/LicenseIssueStart";
import LicenseIssueConfirm from "./pages/LicenseIssueConfirm";
import LicenseIssueUserRegister from "./pages/LicenseIssueUserRegister";
import LicenseIssueUserSearch from "./pages/LicenseIssueUserSearch";
import LicenseIssuePurchaseType from "./pages/LicenseIssuePurchaseType";
import LicenseIssueComplete from "./pages/LicenseIssueComplete";
import LicenseIssueHistory from "./pages/LicenseIssueHistory";
import ProductConfirmForUpdateRequest from "./pages/ProductConfirmForUpdateRequest";
import Download from "./pages/Download";
import ReleaseNotes from "./pages/ReleaseNotes";
import LicensePurchaseForm from "./pages/LicensePurchaseForm";
import UserLicenseReference from "./pages/UserLicenseReference";
import DataManagement from "./pages/dataManagement/DataManagement";
import QATop from "./pages/qa/QATop";
import WebGIS from "./pages/webGIS/WebGIS";
import DTMLink from "./pages/qa/DTMLink";
import PrivacyPolicy from "./pages/statics/PrivacyPolicy";
import PrivacyPolicyEn from "./pages/statics/PrivacyPolicyEn";
import DetailDfwalker from "./pages/productDetails/DetailDfwalker";
import ProductSuccess from "./pages/PaymentComplete";
import PaymentComplete from "./pages/PaymentComplete";
import DetailDfbird from "./pages/productDetails/DetailDfbird";
import ProductConfirmDfscanner from "./pages/private/ProductConfirmDfscanner";
import ProductConfirmDflat from "./pages/private/ProductConfirmDflat";

/**
 * 各ページへのルーティング定義コンポーネント
 * @returns
 */
function AppRoutes() {
    return (
        <BrowserRouter>
            <ErrorBoundary>
                <Routes>
                    {/* 以下は未認証でも開けるページ */}
                    <Route path={HOME_ROUTE} element={<Top />} />
                    <Route path={SIGNIN_ROUTE} element={<Signin />} />
                    <Route path={SIGNUP_ROUTE} element={<Signup />} />
                    <Route path={SIGNUP_CONFIRM_ROUTE} element={<SignupConfirm />} />
                    <Route path={RESER_PASSWORD_ROUTE} element={<ResetPassword />} />
                    <Route path={COMPLETE_PASSWORD_ROUTE} element={<CompletePassword />} />
                    <Route path={REQUIRED_SIGNIN_ROUTE} element={<RequiredSignin />} />
                    <Route path={COMMON_ERROR_ROUTE} element={<CommonError />} />
                    <Route path={PRODUCT_SELECT_ROUTE} element={<ProductSelect />} />
                    <Route path={HELP_ROUTE} element={<Help />} />
                    <Route path={DETAIL_DF_SCANNER_ROUTE} element={<DetailDfscanner />} />
                    <Route path={DETAIL_DF_LAT_ROUTE} element={<DetailDflat />} />
                    <Route path={DETAIL_DF_VIEWER_ROUTE} element={<DetailDfviewer />} />
                    <Route path={DETAIL_DF_WALKER_ROUTE} element={<DetailDfwalker />} />
                    <Route path={DETAIL_DF_BIRD_ROUTE} element={<DetailDfbird />} />
                    <Route path={DOWNLOAD_ROUTE} element={<Download />} />
                    <Route path={RELEASE_NOTES_ROUTE} element={<ReleaseNotes />} />
                    <Route path={LICENSE_PURCHASE_FORM} element={<LicensePurchaseForm />} />
                    <Route path={QA_ROUTE} element={<QATop />} />
                    <Route path={DTM_LINK_ROUTE} element={<DTMLink />} />
                    <Route path={PRIVACY_POLICY_ROUTE} element={<PrivacyPolicy />} />
                    <Route path={PRIVACY_POLICY_EN_ROUTE} element={<PrivacyPolicyEn />} />
                    <Route path={PAYMENT_COMPLETE_ROUTE} element={<PaymentComplete />} />

                    {/* 以下は認証が必要なページ（未認証の場合はログインページへリダイレクト） */}
                    <Route
                        path={MYPAGE_ROUTE}
                        element={<RouteAuthGuard component={<Mypage />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={ACCOUNT_SETTING_ROUTE}
                        element={<RouteAuthGuard component={<AccountSetting />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={PRODUCT_CONFIRM_ROUTE}
                        element={<RouteAuthGuard component={<ProductConfirm />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={PRODUCT_CONFIRM_TRIAL_ROUTE}
                        element={<RouteAuthGuard component={<ProductConfirmTrial />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={PRODUCT_CONFIRM_UPDATE_ROUTE}
                        element={<RouteAuthGuard component={<ProductConfirmForUpdate />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={PRODUCT_CONFIRM_UPDATE_REQUEST_ROUTE}
                        element={
                            <RouteAuthGuard component={<ProductConfirmForUpdateRequest />} redirect={SIGNIN_ROUTE} />
                        }
                    />
                    <Route
                        path={PRODUCT_COMPLETE_ROUTE}
                        element={<RouteAuthGuard component={<ProductComplete />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={USER_LICENSE_ROUTE}
                        element={<RouteAuthGuard component={<UserLicense />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={USER_LICENSE_REFERENCE_ROUTE}
                        element={<RouteAuthGuard component={<UserLicenseReference />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={USER_CREATE_ROUTE}
                        element={<RouteAuthGuard component={<UserCreate />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={USER_UPDATE_ROUTE}
                        element={<RouteAuthGuard component={<UserUpdate />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={LICENSE_LINKING_ROUTE}
                        element={<RouteAuthGuard component={<LiseLinking />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={LICENSE_DETAIL_ROUTE}
                        element={<RouteAuthGuard component={<LicenseDetail />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={PAYMENT_HISTORY_ROUTE}
                        element={<RouteAuthGuard component={<PaymentHistoryList />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={DATA_MANAGEMENT_ROUTE}
                        element={<RouteAuthGuard component={<DataManagement />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={WEB_GIS_ROUTE}
                        element={<RouteAuthGuard component={<WebGIS />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={LICENSE_ISSUE_START_ROUTE}
                        element={<RouteAuthGuard component={<LicenseIssueStart />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={LICENSE_ISSUE_USER_REGISTER_ROUTE}
                        element={<RouteAuthGuard component={<LicenseIssueUserRegister />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={LICENSE_ISSUE_USER_SEARCH_ROUTE}
                        element={<RouteAuthGuard component={<LicenseIssueUserSearch />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={LICENSE_ISSUE_PURCHASE_TYPE_ROUTE}
                        element={<RouteAuthGuard component={<LicenseIssuePurchaseType />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={LICENSE_ISSUE_CONFIRM_ROUTE}
                        element={<RouteAuthGuard component={<LicenseIssueConfirm />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={LICENSE_ISSUE_COMPLETE_ROUTE}
                        element={<RouteAuthGuard component={<LicenseIssueComplete />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={LICENSE_ISSUE_HISTORY_ROUTE}
                        element={<RouteAuthGuard component={<LicenseIssueHistory />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={COMMON_UNAUTHORIZED_ROUTE}
                        element={<RouteAuthGuard component={<CommonUnauthorized />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={PRODUCT_CONFIRM_DFSCANNER_ROUTE}
                        element={<RouteAuthGuard component={<ProductConfirmDfscanner />} redirect={SIGNIN_ROUTE} />}
                    />
                    <Route
                        path={PRODUCT_CONFIRM_DFLAT_ROUTE}
                        element={<RouteAuthGuard component={<ProductConfirmDflat />} redirect={SIGNIN_ROUTE} />}
                    />

                    {/* 存在しないパス */}
                    <Route path="*" element={<CommonNotFound />} />
                </Routes>
            </ErrorBoundary>
        </BrowserRouter>
    );
}

export default AppRoutes;

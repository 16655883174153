import { API } from "aws-amplify";
import { API_ENDPOINT_NAME } from "../consts/constants";
import { loggingInfo, loggingWarn } from "../utils/LoggingUtils";

/**
 * TIFF変換処理の呼び出し
 *
 * @param key 対象ファイルのS3キー
 * @param resizeDisabled リサイズの可否
 * @returns TIFF変換処理呼び出しAPIレスポンス
 */
export async function invokeTiffConvert(idToken: string, key: string, resizeDisabled: boolean) {
    const path = `/tiff_convert_invoke`;
    const body: { [name: string]: any } = {};
    body["key"] = key;
    body["resize_disabled"] = resizeDisabled;
    const request = {
        body: body,
        headers: {
            Authorization: idToken,
        },
    };
    try {
        const response = await API.post(API_ENDPOINT_NAME, path, request);
        loggingInfo("[TiffConvertInvoke] Succeed:", response);
        return response;
    } catch (error: any) {
        loggingWarn("[TiffConvertInvoke] Failed:", error);
        throw error;
    }
}

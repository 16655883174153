import { MouseEventHandler } from "react";
import { Link } from "react-router-dom";

import dflat_icon_lite from "./../../img/dflat/icon_lite.png";
import dflat_screen from "./../../img/dflat/screen.png";
import { DETAIL_DF_LAT_ROUTE, DOWNLOAD_ROUTE } from "../../consts/routes";
import { PRODUCT_TYPE_ID_DF_LAT_LITE } from "../../consts/constants";
import { Trans, useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import i18n from "../../i18n/configs";

type PanelProps = {
    handleSelectProduct: MouseEventHandler<HTMLButtonElement>;
    handleSelectQuote: MouseEventHandler<HTMLButtonElement>;
    handleSelectProductAsFreeTrial: MouseEventHandler<HTMLButtonElement>;
};

/**
 * DF LAT Lite 商品パネル
 * @returns JSX.Element
 */
const DfLatLitePanel: React.FC<PanelProps> = ({
    handleSelectProduct,
    handleSelectQuote,
    handleSelectProductAsFreeTrial,
}) => {
    const { t } = useTranslation();

    return (
        <div className="flex h-full w-full max-w-sm flex-col justify-between rounded-lg border border-gray-300 bg-green-50 bg-opacity-50 px-10 pt-5 shadow">
            <div className="flex items-center justify-center">
                <img className="h-20 w-20 p-3" src={dflat_icon_lite} alt="dflat" />
                <div>
                    <label className="text-3xl font-semibold tracking-tight text-lime-900">DF LAT</label>
                    <br />
                    <label className="text-2xl font-semibold tracking-tight text-lime-900">
                        {t("CommonLabel.lite")}
                    </label>
                </div>
            </div>
            <img className="px-8 py-3" src={dflat_screen} alt="dflat" />
            {i18n.language === "ja" ? (
                <div className="pb-3">
                    <p className="text-sm font-bold text-lime-800">
                        <Trans
                            i18nKey="ProductSelect.priceForGeneral"
                            values={{ yenPerYear: "45", yenPerMonth: "10" }}
                        />
                    </p>
                    <p className="text-sm font-bold text-lime-800">
                        <Trans i18nKey="ProductSelect.priceForForest" values={{ yenPerYear: "20", yenPerMonth: "5" }} />
                    </p>
                    <p className="text-sm font-bold text-lime-800">
                        <Trans
                            i18nKey="ProductSelect.priceForEducational"
                            values={{ yenPerYear: "20", yenPerMonth: "5" }}
                        />
                    </p>
                </div>
            ) : (
                <></>
            )}
            <p className="text-sm text-gray-800">
                <Trans
                    i18nKey="ProductSelect.dflatLiteText"
                    components={{
                        l: <Link className="link-text" to={DETAIL_DF_LAT_ROUTE} />,
                    }}
                />
            </p>
            <p className="text-sm text-gray-800">
                *
                <Trans
                    i18nKey="ProductSelect.proLite"
                    components={{
                        l: <HashLink className="link-text" to={DOWNLOAD_ROUTE + "#availableTools"} smooth />,
                    }}
                />
            </p>
            <div className="flex flex-col items-center justify-center gap-3 pb-4 pt-4">
                {/* クレジット購入ボタンは非表示にしておく */}
                {/* <button
                    id="purchase-dflat-btn"
                    onClick={handleSelectProduct}
                    className="text-lx btn-green-white h-full w-2/3"
                    value={PRODUCT_TYPE_ID_DF_LAT_LITE}
                >
                    {t("ProductSelect.purcahseLicense")}
                </button> */}
                <button
                    id="quote-dfscanner-btn"
                    onClick={handleSelectQuote}
                    className="text-lx btn-green-white h-full w-2/3 py-1.5"
                    value={PRODUCT_TYPE_ID_DF_LAT_LITE}
                >
                    {t("ProductSelect.requestQuote")}
                </button>
                <button
                    id="trial-dflat-btn"
                    onClick={handleSelectProductAsFreeTrial}
                    className="text-lx btn-white h-full w-2/3 py-1.5"
                    value={PRODUCT_TYPE_ID_DF_LAT_LITE}
                >
                    {t("ProductSelect.tryFreeTrial")}
                </button>
            </div>
        </div>
    );
};

export default DfLatLitePanel;

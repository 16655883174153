import {
    DF_BIRD_FILENAME_V1_0_0,
    DF_BIRD_FILENAME_V1_0_1,
    DF_BIRD_FILENAME_V1_0_2,
    DF_BIRD_FILENAME_V1_0_3,
    DF_BIRD_URL_V1_0_0,
    DF_BIRD_URL_V1_0_1,
    DF_BIRD_URL_V1_0_2,
    DF_BIRD_URL_V1_0_3,
} from "../../consts/Installers";

/**
 * リリースノート英語版（DF BIRD・英語版）
 * @returns JSX.Element
 */
export const ReleaseNotesDfBirdEn = () => {
    return (
        <div>
            {/* v1.0.3 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF BIRD v1.0.3</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-11-29</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF BIRD v1.0.3 has been released.</p>
                    <br />
                    <p>Improved Functions</p>
                    <p>・The accuracy of creating orthoimages has been improved.</p>
                    <p>・Automated image import when processing is complete.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_BIRD_URL_V1_0_3}>
                    {DF_BIRD_FILENAME_V1_0_3}
                </a>
            </div>

            {/* v1.0.2 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF BIRD v1.0.2</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-11-08</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF BIRD v1.0.2 has been released.</p>
                    <br />
                    <p>Improved Functions</p>
                    <p>・Speeding up SfM processing.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_BIRD_URL_V1_0_2}>
                    {DF_BIRD_FILENAME_V1_0_2}
                </a>
            </div>

            {/* v1.0.1 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF BIRD v1.0.1</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-10-25</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF BIRD v1.0.1 has been released.</p>
                    <br />
                    <p>Bug Fixes</p>
                    <p>・Fixed an issue where some PCs would not start.</p>
                    <p>・Fixed an issue where some orthoimages were not drawing properly.</p>
                    <p>・Fixed a bug in polygon drawing.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_BIRD_URL_V1_0_1}>
                    {DF_BIRD_FILENAME_V1_0_1}
                </a>
            </div>

            {/* v1.0.0 */}
            <div className="mb-10 rounded-lg border border-gray-300 p-4 shadow">
                <h2 className="my-2 text-3xl font-bold">DF BIRD v1.0.0</h2>
                <h2 className="my-2 text-lg font-bold text-gray-500">2024-09-18</h2>
                <hr className="my-2"></hr>
                <div>
                    <p>DF BIRD v1.0.0 has been released.</p>
                    <br />
                </div>
                <hr className="my-2"></hr>
                <p className="mt-2">Download</p>
                <a className="link-text-blue" href={DF_BIRD_URL_V1_0_0}>
                    {DF_BIRD_FILENAME_V1_0_0}
                </a>
            </div>
        </div>
    );
};

export default ReleaseNotesDfBirdEn;
